import React from 'react';
import deleteImage from '../photos/deleteImage.svg';

const Delete = () => {
  return (
    <div className="import">
      <img src={deleteImage} alt="deleteImage" />
    </div>
  );
}

export default Delete